import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../../hooks/useAxios';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputSelect, InputText } from '../../../components/Inputs';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { MsgInput } from '../../../components/MsgInput/MsgInput';
import { postUsuarioAdapterSchema } from '../../../adapters/usuario.adapter';
import { PostConsultorJSON } from '../../../services/consultor.service';
import { PostUsuarioJSON, GetUsuarioJSON } from '../../../services/usuario.service';
import { Loader } from '../../../components/Loader/Loader';

// import './ProcessosMain.css'

export const ModalCrearUsuario = ({ data, handleCloseModal, fetchRCPData,
    fetchVerificadoresData, fetchIdiomasData, fetchUsuarios }) => {

    const history = useHistory();

    const [fetchCrearUsuario, fetchCrearUsuarioData] = PostUsuarioJSON()

    const [fetchMostrarUsuario, fetchMostrarUsuarioData] = GetUsuarioJSON(data?.id)

    const [fetchUpdateUsuario, fetchUpdateUsuarioData] = useAxios('patch', `/usuario-admin/caateeb/${data?.id}/update`)

    const [fetchVerificarUsuario, fetchVerificarUsuarioData] = useAxios('POST','usuario-admin/caateeb/verificar')

    const [formCrearUsuario, 
        handleInputChangeCrearDAPcons, 
        handleFormChangeCrearDAPcons, 
        handleResetCrearDAPcons] = useForm(postUsuarioAdapterSchema())

    const [valuesValidCrearDAPcons, handleVerifiedValuesCrearDAPcons, handleResetValuesCrearDAPcons] = useValidator({
        nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        apellidos: { isOk: true, msgError: [`Los apellidos son obligatorio.`] },
        usuario: { isOk: true, msgError: [`El usuario es obligatorio.`] },
        email: { isOk: true, msgError: [`El email es obligatorio`] },
    })

    const handleSubmitAltaDAPcons = () => {

        if (handleVerifiedValuesCrearDAPcons(formCrearUsuario)) {
            fetchCrearUsuario({
                body: {
                    ...formCrearUsuario
                }
            })
        }
    }

    const handleSubmitFormUsuario = (e) => {
        e.preventDefault()
        if (handleVerifiedValuesCrearDAPcons(formCrearUsuario)) {
            fetchUpdateUsuario({ 
                body: formCrearUsuario
            })
        }
    }

    useEffect(() => {
        console.log(fetchCrearUsuarioData)
        if (fetchCrearUsuarioData?.status === 201) {
            fetchUsuarios()
            handleCloseModal()
        }

    }, [fetchCrearUsuarioData])

    useEffect(() => {
        if (fetchUpdateUsuarioData.data?.status === 200) {
            fetchUsuarios()
            handleCloseModal()
        }

    }, [fetchUpdateUsuarioData])

    useEffect(() => {
        if(data.value){
            if (data?.id) {
                fetchMostrarUsuario();
            }
        }else{
            handleResetCrearDAPcons();
        }
    }, [data.value])

    useEffect(() => {
        if (fetchMostrarUsuarioData.status === 200) {
            handleFormChangeCrearDAPcons(fetchMostrarUsuarioData.data)
        }
    }, [fetchMostrarUsuarioData])

     const handleChange = (event) => {
        const { checked, value } = event.target;

        console.log(checked);

        if (checked) {
            handleFormChangeCrearDAPcons({
                ...formCrearUsuario,
                perfiles: [...formCrearUsuario.perfiles, parseInt(value)],
            });
        } else {
            const items = formCrearUsuario.perfiles.filter((item) => item !== parseInt(value));
            handleFormChangeCrearDAPcons({
                ...formCrearUsuario,
                perfiles: [...items],
            });
        }
    };

    const handleBlur = (event) => {
       const { value } = event.target;
       fetchVerificarUsuario({
         body:{
            nif:value,
         }
       });
    };

    useEffect(() => {
       if(fetchVerificarUsuarioData.data?.data?.data){
            const user = fetchVerificarUsuarioData.data?.data?.data;
            handleFormChangeCrearDAPcons(user);
       }else{
          handleFormChangeCrearDAPcons({
                ...formCrearUsuario, 
                nombre: '',
                apellidos: '', 
                email: '', 
                telefono: '', 
                perfiles: [], 
                id: ''
          })
       } 
    }, [fetchVerificarUsuarioData.data])
    return (
        <Modal show={data.value} onHide={handleCloseModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>{data.action != 'CREATE' ? 'Ficha de actualización de Usuario Caateeb' : 'Ficha de creación de Usuario Caateeb' }</Modal.Title>
            </Modal.Header>
            {fetchMostrarUsuarioData.loading ? <Loader /> : <>
            <Modal.Body className='px-4 py-4'>
                <Form className='row'>

                    <Form.Group className="mb-4 col-sm-7 col-lg-3">
                        <InputText
                            label='NIF:'
                            className=''
                            name='usuario'
                            value={formCrearUsuario.usuario}
                            onChange={handleInputChangeCrearDAPcons}
                            onBlur={handleBlur}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearUsuarioData}
                            disabled={fetchVerificarUsuarioData.loading}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4 col-sm-6 col-lg-4">
                        <InputText
                            label='Nombre:'
                            className=''
                            name='nombre'
                            value={formCrearUsuario.nombre}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearUsuarioData}
                            disabled={fetchVerificarUsuarioData.loading}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4 col-sm-8 col-lg-5">
                        <InputText
                            label='Apellidos:'
                            className=''
                            name='apellidos'
                            value={formCrearUsuario.apellidos}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearUsuarioData}
                            disabled={fetchVerificarUsuarioData.loading}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4 col-sm-6 col-lg-3">
                        <InputText
                            label='Teléfono:'
                            className=''
                            name='telefono'
                            value={formCrearUsuario.telefono}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearUsuarioData}
                            disabled={fetchVerificarUsuarioData.loading}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4 col-sm-8 col-lg-5">
                        <InputText
                            label='E-mail:'
                            className=''
                            name='email'
                            value={formCrearUsuario.email}
                            onChange={handleInputChangeCrearDAPcons}
                            validation={valuesValidCrearDAPcons}
                            fetchData={fetchCrearUsuarioData}
                            disabled={fetchVerificarUsuarioData.loading}
                        />
                    </Form.Group>

                    <Form.Group className="col-12 col-sm-4 mt-2 col-xl-12 mb-3 p-3">
                        <Form.Label className="fw-500 form-label">Perfiles de usuario:</Form.Label>
                        <div className="col-12 mt-1">
                        {[
                            {
                                id: 1, nombre: 'Administrador Ares'
                            },
                            {
                                id: 2, nombre: 'Administrador Caateeb'
                            }
                        ].map((check, index) => ( 
                            <div className="d-inline-block me-4" key={index}> 
                              <Form.Check
                                    type="checkbox"
                                    id={`${check.id}`}
                                    label={check.nombre}
                                    value={check.id}
                                    checked={formCrearUsuario.perfiles.some((item) => item === check.id)}
                                    onChange={handleChange}
                                    disabled={fetchVerificarUsuarioData.loading}
                                />
                            </div>
                         ))}    
                        </div>
                    </Form.Group>


                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={() => {
                    handleCloseModal()
                }}>
                    Salir
                </Button>
                {formCrearUsuario.id 
                    ? 
                       <ButtonSpinner
                            variant="warning" type="submit" className="ms-2"
                            handleSubmit={handleSubmitFormUsuario} fetchDataLoading={[fetchUpdateUsuarioData.loading]}
                            value='Actualizar usuario' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                        />   
                   :
                     <ButtonSpinner
                            variant="info" type="submit" className="ms-2"
                            handleSubmit={handleSubmitAltaDAPcons} fetchDataLoading={[fetchCrearUsuarioData.loading]}
                            value='Crear Usuario' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                        />
                }

            </Modal.Footer>
             </>
         }
        </Modal>
    )
}
