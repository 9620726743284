import React, { useContext, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faSort, faEdit, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../components/Loader/Loader';
import { PaginationT } from '../../../components/PaginationT/PaginationT';

import { useLang } from '../../../language/index';
import { useHistory } from 'react-router-dom';
import ButtonOrderBy from '../../../components/ButtonOrderBy/ButtonOrderBy';
import { useAxios } from '../../../hooks/useAxios';
import { AuthContext } from '../../../auth/AuthContext';
import { isModuleOrganizacion } from '../../../helpers/helpers';
import { ModalDocumento } from './ModalDocumento';
import { useModalData } from '../../../hooks/useModalData';

export const DocumentosMain = ({ fetchDocumentosData, formValuesSearch, namePage = 'default', handleFormChange, fetchDocumentos }) => {

    const history = useHistory();

    const Lang = useLang();

    const [dataModal_Documento, handleOpenModal_Documento, handleCloseModal_Documento] = useModalData()

    useEffect(() => {
    }, [])

    return (
        <>
            <div className="col">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">

                    <div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                        <div>
                            <h5 className='mb-3 mb-lg-0'>{Lang('LISTA_DE')} Documentos</h5>
                        </div>
                        <div className='d-flex justify-content-end ms-auto'>
                            <Button variant="default" className="px-3 ms-3 d-flex align-items-center"
                                onClick={() => {
                                    handleOpenModal_Documento({
                                        action: 'CREATE'
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faFile} className='me-2' /> <span className=''>&nbsp;Nuevo documento</span>
                            </Button>
                        </div>
                    </div>
                    {
                        fetchDocumentosData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    fetchDocumentosData?.data?.total ?
                                        <>
                                            <Table hover bordered responsive className='tableMain'>
                                                <thead>
                                                    <tr className="text-center">
                                                        <th className='text-nowrap'>Título</th>
                                                        <th className='text-nowrap'>Tipo de documento</th>
                                                        <th className='text-nowrap text-center'>Documento</th>
                                                        <th className='text-nowrap text-center'>Estado</th>
                                                        <th className='text-center'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchDocumentosData?.data?.items?.map((item) => {
                                                        return (
                                                            <tr key={item.id} className="text-center">
                                                                <td valign='middle'>{item.titulo}</td>
                                                                <td valign='middle'>{item.tipo_documento_nombre}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>
                                                                    {item.tipo === 'documento' &&
                                                                        <Button href={item.ruta} target="_blank" download variant="info" size='sm' className='ms-3 my-0'>
                                                                            <FontAwesomeIcon icon={faDownload} /> Descargar
                                                                        </Button>
                                                                    }

                                                                    {item.tipo === 'enlace' &&
                                                                       <Button href={item.link} target="_blank" download variant="info" size='sm' className='ms-3 my-0'>
                                                                            <FontAwesomeIcon icon={faDownload} /> Descargar
                                                                        </Button>
                                                                    }
                                                                </td>
                                                                <td valign='middle' className='text-nowrap text-center'>
                                                                <Badge bg={item.estado == 'Publicado' ? 'success' : 'warning'}>{item.estado}</Badge>
                                                                </td>
                                                                <td valign='middle' className='text-center'>
                                                                    <Button variant="secondary" size='sm' className='ms-3 my-0'
                                                                        onClick={
                                                                            () => handleOpenModal_Documento({
                                                                                action: 'EDIT',
                                                                                id: item.id,
                                                                            })
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchDocumentosData?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchDocumentosData?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                                }
                            </>
                    }
                </div>
            </div>
            <ModalDocumento
                data={dataModal_Documento}
                handleCloseModal={handleCloseModal_Documento}
                fetchDocumentos={fetchDocumentos}
            />
        </>
    )
}
