import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef } from 'react'
import { Table, Button } from 'react-bootstrap'
import { Loader } from '../../../components/Loader/Loader'
import { useAxios } from '../../../hooks/useAxios';
import {ModalInfoPagador} from './ModalInfoPagador'
import {ModalRegistroPagador} from './ModalRegistroPagador'
import { useModalData } from '../../../hooks/useModalData';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { InputCheck } from '../../../components/Inputs/InputCheck.jsx'
import { ModalCondicionesContratacion } from "./ModalCondicionesContratacion";
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';

export const DatosFacturacion = ({dapconsId, handleCloseModal, handleShowModalSuccess}) => {
    const [dataModal_Infopagador, handleOpenModal_Infopagador, handleCloseModal_Infopagador] = useModalData()
    const [dataModal_Registropagador, handleOpenModal_Registropagador, handleCloseModal_Registropagador] = useModalData()
    const [fetchTarifa, fetchTarifaData] = useAxios('GET', `/dapcons/detalle_tarifa`)
    const [fetchPagar, fetchPagarData] = useAxios('POST', '/dapcons/pagar')
    const [documento, setDocumento] = useState(1);
    const [pagador, setPagador] = useState('');
    const [dataHtml, setdataHtml] = useState('')
    const inputEl = useRef(null);

    const handleChangeDocumento = (e) => {
        setDocumento(e.target.value)
    }

    const handleSubmitPagar = (e) => {
        e.preventDefault();
        fetchPagar({
            body:{
                    url_redireccion_ok: `${process.env.PUBLIC_URL}/DAPcons`,
                    url_redireccion_ko: `${process.env.PUBLIC_URL}/DAPcons`,
                    //url_redireccion_ok: `${'http://localhost:3000'}/DAPcons`,
                    //url_redireccion_ko: `${'http://localhost:3000'}/DAPcons`,
                    dapcons_id: dapconsId,
                    pagador
            }
        });
    }

    useEffect(() => {
        fetchTarifa({
            params: {
                dapcons_id: dapconsId
            }
        });
    },[])

    useEffect(() => {
        if (fetchPagarData.data?.status === 200) {
            const dapcons = fetchPagarData.data?.data?.data;
            if(dapcons?.pagoVisaResult){
                setdataHtml(dapcons?.pagoVisaResult)
            }else{
                handleShowModalSuccess()
                handleCloseModal()
            }
        }
    }, [fetchPagarData])

    useEffect(() => {
        if (dataHtml) {
            if (inputEl.current) {
                try {
                    inputEl.current.children[0].submit()
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }, [dataHtml])

    const [checked, setChecked] = useState(false);

    const [checkedManifiesto, setCheckedManifiesto] = useState({
        m1: false,
        m2: false,
        m3: false,
    });

    const [showModalManifiesto, setShowModalManifiesto] = useState({
        value: false,
        key: '',
        to: '',
    });
    const handleCloseModalManifiesto = () => setShowModalManifiesto({
        value: false,
        key: '',
        to: '',
    })
    const handleShowModalManifiesto = ({ key, to }) => setShowModalManifiesto({
        value: true,
        key,
        to,
    })

    return (
        <div className="row">
          <div className="col-12">
            {fetchTarifaData.loading ? <Loader /> : <>
                <Table bordered hover responsive className="mt-3">
                    <tbody>
                    <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>Nombre:</strong><br/>
                        </td>
                        <td>
                         {fetchTarifaData.data?.data?.data?.nombre || ''}
                        </td>
                    </tr>   
                    <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>CIF / NIF:</strong><br/>
                        </td>
                        <td>
                         {fetchTarifaData.data?.data?.data?.documento || ''}
                        </td>
                    </tr>   
                     <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>Email:</strong><br/>
                        </td>
                        <td>
                         {fetchTarifaData.data?.data?.data?.email || ''}
                        </td>
                    </tr>  
                    {fetchTarifaData.data?.data?.data?.es_convenio != 1  && <>
                      
                      <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>Tarifa:</strong><br/>
                        </td>
                        <td>
                         {fetchTarifaData.data?.data?.data?.descripcion || ''}
                        </td>
                      </tr>
              
                      <tr>
                         <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>Precio tarifa:</strong><br/>
                         </td>
                         <td>
                          € {fetchTarifaData.data?.data?.data?.precio || ''}
                         </td>
                      </tr>

                      {fetchTarifaData.data?.data?.data?.descripcion_extra &&

                          <tr>  
                            <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                              <strong>Costo por Idioma</strong><br/>
                            </td>
                            <td>
                             €  {fetchTarifaData.data?.data?.data?.precio_extra || 0}
                            </td>
                          </tr>                  
                      }

                      <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>Total :</strong><br/>
                        </td>
                        <td>
                           <b>€ {((parseInt(fetchTarifaData.data?.data?.data?.precio || 0)) + (parseInt(fetchTarifaData.data?.data?.data?.precio_extra || 0))).toFixed(2) }</b>
                        </td>
                      </tr>

                      <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>Acciones :</strong><br/>
                        </td>
                        <td>
                           <Button variant="outline-dark" className='px-3' onClick={() => {
                                handleOpenModal_Infopagador({
                                    action: 'CREATE'
                                })
                            }}>
                               Cambiar pagador
                            </Button>
                        </td>
                      </tr>
                    </>
                    }  
                    {fetchTarifaData.data?.data?.data?.es_convenio == 1  &&
                        <tr>
                            <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                              <strong>Convenio:</strong><br/>
                            </td>
                            <td>
                             {fetchTarifaData.data?.data?.data?.convenio || ''}
                            </td>
                       </tr>
                     }  
                    </tbody>
                </Table>   

                <div className="col-12">
                  <div className="border-bottom">
                    <div className="my-3 col-12 ">
                        <InputCheck
                            label={
                                <small className="d-block">
                                    <span>MANIFIESTO que he leído y ACEPTO el contenido de la información precedente relativa a la</span> <span className="text-danger cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        handleShowModalManifiesto({
                                            key: 'm1',
                                            to: '10',
                                        })
                                    }
                                    }>
                                        protección de datos de carácter personal.
                                    </span>

                                </small>
                            }
                            name="manifiesto1"
                            value={checkedManifiesto.m1}
                            onChange={
                                (e) => {
                                    setCheckedManifiesto({
                                        ...checkedManifiesto,
                                        m1: e?.target?.checked
                                    })
                                }
                            }
                        />
                    </div>
                    <div className="my-3 col-12 ">
                        <InputCheck
                            label={
                                <small className="d-block">
                                    <span>MANIFIESTO que he leído y ACEPTO que, si facilito los</span> <span onClick={
                                        (e) => {
                                            e.preventDefault()
                                            handleShowModalManifiesto({
                                                key: 'm2',
                                                to: '10',
                                            })
                                        }
                                    } className="text-danger cursor-pointer ">
                                        datos a terceras personas, tendré que informar y obtener su consentimiento previo en relación con los aspectos relacionados en el presente documento.
                                    </span>
                                </small>
                            }
                            name="manifiesto2"
                            value={checkedManifiesto.m2}
                            onChange={
                                (e) => {
                                    setCheckedManifiesto({
                                        ...checkedManifiesto,
                                        m2: e?.target?.checked
                                    })
                                }
                            }
                        />
                    </div>
                    <div className="my-3 col-12 ">
                        <InputCheck
                            label={
                                <small className="d-block">
                                    MANIFIESTO que autorizo a que mis datos personales sean facilitados al Consejo General de la Arquitectura Técnica de España, a la Administración, etc. para finalidades estadísticas y de estudio sobre la situación y afectación del radón en nuestro territorio.
                                </small>
                            }
                            name="manifiesto3"
                            value={checkedManifiesto.m3}
                            onChange={
                                (e) => {
                                    setCheckedManifiesto({
                                        ...checkedManifiesto,
                                        m3: e?.target?.checked
                                    })
                                }
                            }
                        />
                    </div>
                </div>
               </div> 

                <div className="col-12 text-center">
                    <MsgInputServer fetchData={fetchPagarData} className='mt-2' />
                </div>

                <div className="col-12 text-center">
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2 mt-2"
                        handleSubmit={handleSubmitPagar} fetchDataLoading={[fetchPagarData.loading]}
                        value='Ir a Pagar' icon={<FontAwesomeIcon icon={faArrowRight} className='me-1' />}
                        disabled={!checkedManifiesto.m1 || !checkedManifiesto.m2 || !checkedManifiesto.m3 ? true : false}
                    />
                </div>
            </>
          }  
          <div ref={inputEl} dangerouslySetInnerHTML={{ __html: dataHtml }}></div>
         </div>
         <ModalInfoPagador
          data={dataModal_Infopagador}
          handleCloseModal={handleCloseModal_Infopagador}
          handleChangeDocumento={handleChangeDocumento}
          documento={documento}
          fetchTarifa={fetchTarifa}
          setPagador={setPagador}
          handleOpenModalRegistroPagador={handleOpenModal_Registropagador}
          dapconsId={dapconsId}
         /> 

         <ModalRegistroPagador
          data={dataModal_Registropagador}
          handleCloseModal={handleCloseModal_Registropagador}
          documento={documento}
          fetchTarifa={fetchTarifa}
          setPagador={setPagador}
          dapconsId={dapconsId}
         />

         <ModalCondicionesContratacion
            setCheckedManifiesto={setCheckedManifiesto}
            checkedManifiesto={checkedManifiesto}
            showModal={showModalManifiesto}
            handleCloseModal={handleCloseModalManifiesto}
        />

       </div>  
    )
}
